import React, { useEffect, useRef, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Paper,
  Box,
  StepIcon,
  useMediaQuery,
} from '@mui/material'
import CRMmobile from '../../assests/img/products/CRMMobile2.webp'

import ERPmobile from '../../assests/img/products/ERPMobile.webp'
import Loyaltymobile from '../../assests/img/products/Loyaltymobile.webp'
import Main from './Products/Main'
import ERPFeatures from './Products/ERPFeatures'
import ERPModules from './Products/ERPModules'
import Cross from './Products/CrossPlat'
import CRMWhy from './Products/CRMWhy'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import { useTheme } from '@mui/material/styles'
import CustomTimeline from './Products/CRMTimeLine'
import LoyaltyList from './Products/LoyaltyList'
import { useTranslation } from 'react-i18next'

export default function VerticalScrollStepper() {
  const [activeStep, setActiveStep] = useState(0)
  const sectionRefs = [useRef(null), useRef(null), useRef(null)]
  const steps = ['ERP', 'CRM', 'Loyalty']

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  const { t } = useTranslation()
  useEffect(() => {
    const observers = sectionRefs.map((ref, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            //    console.log(index)
            setActiveStep(index)
          }
        },
        { threshold: 0.1 }
      )

      if (ref.current) {
        observer.observe(ref.current)
      }

      return observer
    })

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [])

  const handleStepClick = step => {
    setActiveStep(step)
    sectionRefs[step].current?.scrollIntoView({ behavior: 'smooth' })
  }

  const data = [
    {
      computerImg: theme.backgroundImage.erp,
      mobileImg: ERPmobile,
      firstLine: t('products.erp_first_line'),
      SecondLine: t('products.erp_second_line'),
      highlightedSecondLone: t('products.erp_highlighted_second_line'),
      firstPara: t('products.erp_first_para'),
      secondPara: t('products.erp_second_para'),
    },
    {
      computerImg: theme.backgroundImage.crm,
      mobileImg: CRMmobile,
      firstLine: t('products.crm_first_line'),
      SecondLine: t('products.crm_second_line'),
      highlightedSecondLone: t('products.crm_highlighted_second_line'),
      firstPara: t('products.crm_first_para'),
      secondPara: t('products.crm_second_para'),
    },
    {
      computerImg: theme.backgroundImage.lyt,
      mobileImg: Loyaltymobile,
      firstLine: t('products.lyt_first_line'),
      SecondLine: t('products.lyt_second_line'),
      highlightedSecondLone: t('products.lyt_highlighted_second_line'),
      firstPara: t('products.lyt_first_para'),
      secondPara: t('products.lyt_second_para'),
    },
  ]
  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const index = steps.findIndex(step => `#${step.toLowerCase()}` === hash)
      if (index !== -1) {
        setActiveStep(index)
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, []) // Run only on mount

  return (
    <Box
      sx={{
        mt: { md: '72px', lg: 0 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'column' },
        overflow: 'hidden', // Prevents overflow
        width: '100%', // Full width
        boxSizing: 'border-box', // Ensures padding is included in width
      }}
    >
      <Box
        sx={{
          mt: { md: '72px', lg: 0 },
          display: 'flex',
          flexDirection: { sx: 'row', md: 'row', lg: 'column' },
          width: '100%',
        }}
      >
        {/* Move Stepper to the right */}
        <Box
          style={{ flexGrow: 1 }}
          sx={{
            width: '100%',
          }}
        >
          <section id="erp" ref={sectionRefs[0]}>
            <Main
              computerImg={data[0].computerImg}
              mobileImg={data[0].mobileImg}
              firstLine={data[0].firstLine}
              SecondLine={data[0].SecondLine}
              highlightedSecondLone={data[0].highlightedSecondLone}
              firstPara={data[0].firstPara}
              secondPara={data[0].secondPara}
            />
            {/* <ERPSoftwareSolution /> */}
            <ERPModules />
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column', // Stack on mobile
              }}
            >
              <Cross />
            </Box>

            <ERPFeatures />
          </section>

          <section id="crm" ref={sectionRefs[1]}>
            <Main
              computerImg={data[1].computerImg}
              mobileImg={data[1].mobileImg}
              firstLine={data[1].firstLine}
              SecondLine={data[1].SecondLine}
              highlightedSecondLone={data[1].highlightedSecondLone}
              firstPara={data[1].firstPara}
              secondPara={data[1].secondPara}
            />
            <CustomTimeline />
            <CRMWhy />
          </section>

          <section id="loyalty" ref={sectionRefs[2]}>
            <Main
              computerImg={data[2].computerImg}
              mobileImg={data[2].mobileImg}
              firstLine={data[2].firstLine}
              SecondLine={data[2].SecondLine}
              highlightedSecondLone={data[2].highlightedSecondLone}
              firstPara={data[2].firstPara}
              secondPara={data[2].secondPara}
            />
            <LoyaltyList />
          </section>
        </Box>
        {/* Paper component on the right side */}
        {/* Paper component on the right side */}
        {!isLarge ? (
          <Paper
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              zIndex: 1000,
              padding: '16px',
              marginTop: '76px', // Adjusts based on your layout needs
            }}
            elevation={4}
          >
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className="p-4"
              nonLinear
            >
              {steps.map((label, index) => (
                <Step key={label} completed={false}>
                  <StepButton
                    onClick={() => handleStepClick(index)}
                    icon={
                      <HorizontalRuleIcon
                        style={{
                          transform: 'rotate(90deg)',
                          color: theme.palette.text.third,
                        }}
                      />
                    }
                  >
                    <StepIcon />
                    <StepLabel
                      icon={<HorizontalRuleIcon />}
                      sx={{
                        direction: 'ltr',
                        '& .MuiStepLabel-label': {
                          fontSize: '1rem',
                          fontWeight: index === activeStep ? 'bold' : 'normal',
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Paper>
        ) : (
          <Paper
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              width: '100%', // Ensures it spans the width of the page or container
              zIndex: 1000,
              padding: '16px',
              marginTop: '76px',
              // backgroundColor: '#f8f9fa', // Light background for better readability
            }}
            elevation={4}
          >
            <Stepper
              activeStep={activeStep}
              orientation="horizontal" // Fixed spelling here
              className="p-4"
              nonLinear
              style={{
                display: 'flex',
                justifyContent: 'center', // Center-aligns the stepper
                gap: '12px', // Adds spacing between steps
              }}
            >
              {steps.map((label, index) => (
                <Step key={label} completed={false}>
                  <StepButton
                    onClick={() => handleStepClick(index)}
                    icon={
                      <HorizontalRuleIcon
                        style={{
                          transform: 'rotate(90deg)', // Rotating the icon for a custom look
                          color: '#64c290', // Custom color from your theme
                        }}
                      />
                    }
                  >
                    <StepLabel
                      sx={{
                        direction: 'ltr',
                        '& .MuiStepLabel-label': {
                          fontSize: '1rem',
                          fontWeight: index === activeStep ? 'bold' : 'normal',
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Paper>
        )}
      </Box>
    </Box>
  )
}
