import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Container,
} from '@mui/material'
import { keyframes } from '@mui/system'
import { useTranslation } from 'react-i18next'

export default function LoyaltyList() {
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const { t } = useTranslation()

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
  const papersContents = [
    {
      title: t('products.customizable_incentives_title'),
      description: t('products.customizable_incentives_para'),
    },
    {
      title: t('products.seamless_integration_title'),
      description: t('products.seamless_integration_para'),
    },
    {
      title: t('products.attract_and_retain_customers_title'),
      description: t('products.attract_and_retain_customers_para'),
    },
    {
      title: t('products.enhanced_brand_loyalty_title'),
      description: t('products.enhanced_brand_loyalty_para'),
    },
    /*
     {
      title: 'Data-Driven Insights',
      description: 'The program provides valuable analytics that help companies understand customer behaviors and preferences, enabling them to fine-tune their marketing strategies and improve overall customer satisfaction.',
    },
    
    */
  ]
  return (
    <Container sx={{ padding: 1, maxWidth: '90vw' }}>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr', lg: '2fr 2fr' },
          gap: 10,
          mb: 10,
          width: '100%',
        }}
      >
        {/* Second row: 3 cards evenly split */}
        {papersContents.map((papersContent, index) => (
          <Card
            key={index}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              backgroundColor:
                whiteShades[Math.floor(Math.random() * whiteShades.length)],
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 3,
              overflow: 'hidden',
              position: 'relative', // Set position relative for absolute children
              '&:hover': {
                transform: 'scale(1.3)',
              },
            }}
          >
            <Box
              sx={{
                position: 'relative', // Set position relative for absolute children
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '1%', // Adjust the position from the top
                  right:
                    rightLocation[
                      Math.floor(Math.random() * rightLocation.length)
                    ], // Adjust the position from the right
                  width: 0, // Width of the box, will be set by borders
                  height: 0, // Height of the box, will be set by borders
                  borderLeft: '15vw solid transparent', // Left border for the triangle
                  borderRight: '15vw solid transparent', // Right border for the triangle
                  borderBottom: `30vw solid ${
                    whiteShades[Math.floor(Math.random() * whiteShades.length)]
                  }`, // Bottom border for the triangle
                  backgroundColor:
                    whiteShades[Math.floor(Math.random() * whiteShades.length)],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect
                  zIndex: 0, // Ensure it is on top of the parent box
                  transform: 'rotate(0deg)', // Remove rotation or adjust as needed
                  animation: `${spin} 10s infinite ease`, // Keep the animation if needed
                }}
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', // Center items vertically
                justifyContent: 'center', // Center items horizontally
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  margin: '1em',
                  textAlign: 'center', // Center text inside the Box
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: 'secondary', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: 'secondary.main', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.price}
                </Typography>
                <Typography variant="body2" sx={{ color: 'secondary', mt: 1 }}>
                  {papersContent.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  )
}
