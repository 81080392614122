import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CRMWhyCards from './CRMWhyCards'

export default function CRMWhy() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' }, //isMobile ? '1rem' : '3rem',

          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography variant="h2" component="h1" gutterBottom>
            <Typography
              component="span"
              variant="h2"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {t('products.why')}
            </Typography>
          </Typography>
          <Typography
            component="span"
            variant="h2"
            sx={{
              // Highlight background
              color: 'primary.contrastText', // Highlight text color
              padding: '0 0.5rem', // Add padding for emphasis
            }}
          >
            {t('products.matrix_ques')}
          </Typography>
          {/* {t('home.business')} */}
          <Typography variant="body1" mt="3%">
            {/* {t('home.paragraph')} */}
            {t('products.why_crm_para')}
          </Typography>
        </Box>
      </Box>
      <CRMWhyCards />
    </Box>
  )
}
