import React, { Suspense, lazy } from 'react'
import { Box } from '@mui/material'

// Lazy load the components
const Main = lazy(() => import('./HomeComponents/Main'))
const About = lazy(() => import('./HomeComponents/About'))
const Products = lazy(() => import('./HomeComponents/Products'))
const Services = lazy(() => import('./HomeComponents/Services'))
const WhyUs = lazy(() => import('./HomeComponents/WhyUs'))
const ContactUs = lazy(() => import('./HomeComponents/ContactUs'))
const ERPSoftwareSolution = lazy(() => import('./Products/ERPSoftwareSolution'))

function Home() {
  return (
    <Box>
      <Suspense fallback={<div>Loading...</div>}>
        <Main />
        <About />
        <Products />
        <Services />
        <WhyUs />
        <ERPSoftwareSolution />
        <ContactUs />
      </Suspense>
    </Box>
  )
}

export default Home
