import { Box, Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

import business from '../../../assests/img/contactUs/business.webp'
import cash from '../../../assests/img/contactUs/cash.webp'
import handshake from '../../../assests/img/contactUs/handshake.webp'
import phone from '../../../assests/img/contactUs/phone.webp'

export default function InTouch() {
  const { t } = useTranslation()
  const papersContent = [
    {
      title: t('contact_us.sales_title'),
      description: t('contact_us.sales_para'),
    },
    {
      title: t('contact_us.career_title'),
      description: t('contact_us.career_para'),
    },
    {
      title: t('contact_us.partners_title'),
      description: t('contact_us.partners_para'),
    },
    {
      title: t('contact_us.support_title'),
      description: t('contact_us.support_para'),
    },
  ]
  return (
    <Box
      sx={{
        mt: '10vh',

        ml: { md: '0px', lg: '2vw' },
        maxWidth: { md: '100%', lg: '77%', xl: '80% ' },
        padding: 1,
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: '2fr 2fr',
          lg: '2fr 2fr 2fr 2fr',
        },
        gap: 1,
      }}
    >
      {/* Second row: 3 cards evenly split */}
      {[0, 1, 2, 3].map(index => (
        <Card
          key={index}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          ></Box>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', // Center items vertically
              justifyContent: 'center', // Center items horizontally
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                margin: '1em',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                loading="lazy"
                src={[cash, business, handshake, phone][index]}
                alt="icon"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  height: '20%',
                }} // Center the icon horizontally
              />
              <Typography
                variant="h4"
                sx={{
                  color: 'secondary',
                  fontWeight: 'bold',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {papersContent[index].title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'secondary',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {papersContent[index].description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}
