import React from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Global, css } from '@emotion/react'
import LanguageToggle from './LanguageToggle'
import DarkModeToggle from './DarkModeToggle'

const DrawerList = (pages, user2, t, toggleDrawer, theme, handleLinkClick) => (
  <>
    <Global
      styles={css`
        @keyframes fallBounce {
          0% {
            transform: translateY(-50%);
            opacity: 0;
          }
          50% {
            transform: translateY(10px);
            opacity: 1;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}
    />
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.background.paper,
        padding: '16px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {pages.map((page, index) => (
        <Link
          to={`${page.nav}`}
          onClick={() => handleLinkClick(page.nav)}
          key={page.title}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px 16px',
            color: theme.palette.secondary.main,
            textDecoration: 'none',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
            fontWeight: 'normal',
            borderRadius: '4px',
            opacity: 1,
            animation: `fallBounce 0.5s forwards ${index * 0.1}s`,
          }}
        >
          {page.icon && (
            <Box sx={{ marginRight: '10px' }}>
              {React.createElement(page.icon)}
            </Box>
          )}
          {page.title}
        </Link>
      ))}

      <Divider sx={{ margin: '16px 0' }} />

      <Button
        href="http://help.erp.matrixbapps.com/Login"
        sx={{
          width: '100%',
          fontFamily: 'Montserrat, sans-serif',
          color: theme.palette.Navbar.LoginButton.color,
          fontWeight: 300,
          fontSize: '14px',
          textAlign: 'center',
          lineHeight: '33px',
          borderRadius: '17px',
          border: '2px solid ' + theme.palette.Navbar.LoginButton.borderColor,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          textDecoration: 'none',
          marginTop: '16px',
          opacity: 1,
          animation: `fallBounce 0.5s forwards ${pages.length * 0.1 + 0.1}s`,
        }}
      >
        <img
          src={user2}
          alt="User Login"
          style={{
            marginRight: '6px',
            width: '20px',
            height: '20px',
          }}
          loading="lazy"
        />
        <Typography variant="body2">{t('navbar.login')}</Typography>
      </Button>

      <Divider sx={{ margin: '16px 0' }} />

      <Box
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LanguageToggle />
        <DarkModeToggle />
      </Box>
    </Box>
  </>
)

export default DrawerList
