import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import user2 from '../../assests/img/user-square.svg'
import logoImg from '../../assests/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu'
import LanguageToggle from './LanguageToggle'
import DarkModeToggle from './DarkModeToggle'
import Home from '../pages/Home' // import your components
import About from '../pages/HomeComponents/About'
import Products from '../pages/HomeComponents/Products'
import Services from '../pages/HomeComponents/Services'
import EInvoicing from '../pages/Products'
import ContactUs from '../pages/ContactUs'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ConstructionIcon from '@mui/icons-material/Construction'
import { useTranslation } from 'react-i18next'
import DrawerList from './DrawerList'
import EmailIcon from '@mui/icons-material/Email'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import '../../assests/styles/main.css'
import { keyframes } from '@mui/system'

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

function ResponsiveAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const theme = useTheme()
  const navigate = useNavigate()
  const isLarge = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()
  const [hoveredLink, setHoveredLink] = useState(null)
  const handleLinkClick = nav => {
    window.scrollTo(0, 0) // Scroll to top

    if (nav.startsWith('https://trial.masfofah.com/')) {
      // External link: Navigate outside the page
      window.open(nav, '_blank', 'noopener,noreferrer')
    } else {
      navigate(nav) // Navigate to the specified route
    }
  }

  const pagesData = [
    {
      title: t('navbar.home'),
      component: Home,
      icon: HomeIcon,
      nav: '/home',
    },
    {
      title: t('navbar.about_us'),
      component: About,
      icon: PersonIcon,
      nav: '/aboutus',
    },
    {
      title: t('navbar.products'),
      component: Products,
      icon: ShoppingCartIcon,
      nav: '/products',
    },
    {
      title: t('navbar.services'),
      component: Services,
      icon: ConstructionIcon,
      nav: '/services',
    },
    {
      title: t('navbar.e_invoicing'),
      component: EInvoicing,
      icon: SettingsIcon,
      nav: '/einvoicing',
    },
    {
      title: t('navbar.contact_us'),
      component: ContactUs,
      icon: EmailIcon,
      nav: '/contactus',
    },
    {
      title: t('navbar.trial'),
      component: ContactUs,
      icon: AddBusinessIcon,
      nav: 'https://trial.masfofah.com/Tenant/trial',
    },
  ]

  const [pages, setPages] = useState(pagesData)

  useEffect(() => {
    setPages(
      isArabic ? (isLarge ? pagesData : [...pagesData].reverse()) : pagesData
    )
  }, [t, i18n, isLarge])

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setDrawerOpen(open)
  }

  return (
    <AppBar position="fixed" sx={{ padding: 1 }}>
      <Toolbar disableGutters>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{
            ml: 2,
            mr: { sm: 0, md: 2 },
            color: theme.palette.Navbar.LoginButton.borderColor,
            display: { xs: 'flex', an: 'none' },
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            marginRight: 1,
            marginLeft: { sm: 0, md: 2 },
            paddingLeft: { sm: 0, md: 3, lg: 9, xl: 12 },
          }}
          onClick={() => handleLinkClick('/home')}
        >
          <img
            loading="lazy"
            src={logoImg}
            alt="logo"
            style={{
              display: { xs: 'none', md: 'flex' },
              marginRight: 1,
              marginLeft: 2,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', an: 'none' },
            marginRight: 1,
            marginLeft: 'auto',
            paddingLeft: { sm: 7, md: 10 },
          }}
        >
          <LanguageToggle />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', an: 'flex' },
            paddingLeft: '3%',
          }}
        >
          {pages.map((page, index) => (
            <Link
              to={page.nav}
              key={page.title}
              style={{
                marginRight: '25px',
                color:
                  hoveredLink === index || location.pathname === page.nav
                    ? theme.palette.Navbar.hover
                    : theme.palette.Navbar.link,
                textDecoration: 'none',
                position: 'relative',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: isArabic ? '20px' : '18px',
                fontWeight: 'normal',
                overflow: 'hidden',
                display: 'inline-block',
                animation: `${fadeInDown} 0.6s ease-out forwards`,
                opacity: 1,
                animationDelay: `${index * 0.1 + 0.2}s`,
              }}
              onClick={event => {
                event.preventDefault() // Prevent navigation
                handleLinkClick(page.nav) // Call your custom function
              }}
              onMouseEnter={() => setHoveredLink(index)}
              onMouseLeave={() => setHoveredLink(null)}
            >
              {page.title}
              <span
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  height: '2px',
                  width:
                    hoveredLink === index || location.pathname === page.nav
                      ? '100%'
                      : '0',
                  backgroundColor: theme.palette.Navbar.LoginButton.borderColor,
                  transition: 'width 0.3s ease',
                }}
              />
            </Link>
          ))}
        </Box>

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {DrawerList(pages, user2, t, toggleDrawer, theme, handleLinkClick)}
        </Drawer>

        <Box
          sx={{
            flexGrow: 0,
            marginLeft: 'auto',
            display: { xs: 'none', an: 'flex' },
            mr: { lg: 8, xl: 10 },
          }}
        >
          <LanguageToggle />
          <DarkModeToggle />
          <Button
            href="http://help.erp.matrixbapps.com/Login"
            sx={{
              opacity: 1,
              fontFamily: 'Montserrat, sans-serif',
              color: theme.palette.Navbar.LoginButton.color,
              fontWeight: 300,
              fontSize: '12px',
              textAlign: 'center',
              lineHeight: '33px',
              borderRadius: '17px',
              border:
                '2px solid ' + theme.palette.Navbar.LoginButton.borderColor,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
              textDecoration: 'none',
              '&:hover': {
                backgroundColor:
                  theme.palette.Navbar.LoginButton.hover.background,
              },
            }}
          >
            <img
              loading="lazy"
              src={user2}
              alt="User Login"
              style={{
                marginRight: '6px',
                filter: 'none',
                width: '20px',
                height: '20px',
              }}
            />
            <Typography variant="body2">{t('navbar.login')}</Typography>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ResponsiveAppBar
