import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { keyframes } from '@mui/system'

const Scroll = () => {
  const [showScroll, setShowScroll] = useState(false)

  // Scroll to the top function
  const scrollToTop = () => {
    const scrollElement = document.querySelector('.custom-scrollbar')
    scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // Check scroll position and update visibility state
  const checkScrollTop = () => {
    const scrollElement = document.querySelector('.custom-scrollbar')
    if (scrollElement.scrollTop > 300) {
      setShowScroll(true)
    } else {
      setShowScroll(false)
    }
  }

  // Add and remove scroll event listener
  useEffect(() => {
    const scrollElement = document.querySelector('.custom-scrollbar')
    scrollElement.addEventListener('scroll', checkScrollTop)
    return () => {
      scrollElement.removeEventListener('scroll', checkScrollTop)
    }
  }, [])

  // Fade in animation when scrolling down
  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `

  // Fade out animation when scrolling up
  const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `

  return (
    <Box>
      {/* Scroll-to-Top Button */}
      {showScroll && (
        <KeyboardArrowUpIcon
          onClick={scrollToTop}
          sx={{
            width: 50,
            position: 'fixed',
            bottom: '40px',
            left: '50%',
            //transform: 'translateX(-50%)', // Center horizontally
            fontSize: '3rem', // Larger size
            color: 'secondary.main',
            cursor: 'pointer',
            zIndex: 1400, // Stay on top
            animation: `${showScroll ? fadeIn : fadeOut} 0.5s ease`,
            transformOrigin: 'center', // Ensure scaling from the center
            '&:hover': {
              transform: 'scale(1.5)',
              transition: 'transform 0.3s ease-in-out',
              transformOrigin: 'center', // Ensure scaling is from the center
            },
          }}
        />
      )}
    </Box>
  )
}

export default Scroll
