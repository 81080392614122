import React, { lazy, Suspense } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ServicesShuffle = lazy(() => import('./ServicesShuffle'))
const BackgroundCircle = lazy(() => import('../../common/BackgroundCircle'))

export default function Services() {
  const { t } = useTranslation()

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '5%',
        }}
      >
        <Box
          sx={{
            mt: 2,
            flex: 1,
            padding: { xs: '1rem', md: '1rem', lg: '3rem' },
            alignItems: 'center',
          }}
        >
          <Box align="center">
            <Typography variant="h2" component="h1" gutterBottom>
              <Typography
                component="span"
                variant="h2"
                sx={{
                  color: 'primary.contrastText',
                  padding: '0 0.5rem',
                  align: 'center',
                  mb: '0',
                }}
              >
                {t('home.our')}
              </Typography>
            </Typography>
            <Typography
              component="span"
              variant="h2"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'primary.contrastText',
                padding: '0 0.5rem',
              }}
            >
              {t('home.service')}
            </Typography>
            <Typography variant="body1" mt="3%">
              {t('home.service_para')}
            </Typography>
          </Box>
        </Box>
        <ServicesShuffle />
        <BackgroundCircle Nb={1} position={{ top: '20vh', right: '30vw' }} />
        <BackgroundCircle Nb={1} position={{ top: '-40vh', left: '30vw' }} />
      </Box>
    </Suspense>
  )
}
