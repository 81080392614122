import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import GreenLargeBox from '../../../assests/img/products/GreenLargeBox.svg'

export default function Cross() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        mt: { xs: '120px', sm: '100px', md: '72px', lg: 0 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${GreenLargeBox})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // Cover to fill the box
        width: '75vw', // Full width
        height: { xs: 'auto', md: '20vh' }, // Adjust height responsively
        alignSelf: 'center',

        zIndex: 1,
        p: 10,
        borderRadius: '96px', // Add rounded edges here
        mb: '64px',
      }}
    >
      <Typography
        align="center"
        variant="h1"
        sx={{ whiteSpace: 'initial', mb: 3, color: 'primary.main' }}
      >
        {t('products.our_products')}{' '}
        <Typography
          align="center"
          variant="span"
          sx={{
            whiteSpace: 'initial',
            mb: 3,
            color: 'primary.contrastText',
            backgroundColor: 'custom.yellowColor',
          }}
        >
          {t('products.platform_title')}
        </Typography>
      </Typography>
      <Typography
        align="center"
        variant="h3"
        sx={{
          whiteSpace: 'initial',
          mb: 1,
          color: 'primary.main',
          fontSize: '2rem',
        }}
      >
        {t('products.platform_para')}
      </Typography>
    </Box>
  )
}
