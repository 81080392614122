import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Box, useTheme, GlobalStyles } from '@mui/material'
import Navbar from './components/common/Navbar'
import Footer from './components/common/Footer'
import ContactUsFloatingButton from './components/common/ContactUsFloatingButton'
import Scroll from './components/common/Scroll'
import './assests/styles/main.css'

const Home = lazy(() => import('./components/pages/Home'))
const AboutUs = lazy(() => import('./components/pages/AboutUs'))
const ContactUs = lazy(() => import('./components/pages/ContactUs'))
const Products = lazy(() => import('./components/pages/Products'))
const EInvoice = lazy(() => import('./components/pages/EInvoice'))
const Services = lazy(() => import('./components/pages/Services'))
const TestResponsive = lazy(() => import('./components/TestResponsive'))

function App() {
  const theme = useTheme()
  const globalStyles = theme => ({
    html: {
      backgroundColor: theme.palette.background.default,
      overflowY: 'hidden',
    },
  })

  return (
    <div>
      <Box
        className="custom-scrollbar"
        sx={{
          width: '100%',
          height: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: theme.palette.backgroundColor, // Background color based on theme
          backgroundImage: theme.backgroundImage.main,
          backgroundRepeat: 'repeat, repeat, repeat', // Repeat each image
          backgroundPosition: 'center, top left, bottom right', // Positioning each image
          backgroundSize: 'auto, auto, auto', // Adjust size for each image
          minHeight: '100vh', // Full viewport height
        }}
      >
        <Box
          sx={{
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'hidden',
            backgroundColor: theme.palette.backgroundColor, // Background color based on theme
            backgroundImage: theme.backgroundImage.main,
            backgroundRepeat: 'repeat, repeat, repeat', // Repeat each image
            backgroundPosition: 'center, top left, bottom right', // Positioning each image
            backgroundSize: 'auto, auto, auto', // Adjust size for each image
            //  minHeight: '100vh', // Full viewport height
          }}
        >
          <Navbar />
          <GlobalStyles styles={globalStyles} />
          <main>
            <Suspense
              fallback={<div style={{ height: '100px' }}>Loading...</div>}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/products" element={<Products />} />
                <Route path="/einvoicing" element={<EInvoice />} />
                <Route path="*" element={<Home />} />
                <Route path="/test" element={<TestResponsive />} />
                <Route path="/services" element={<Services />} />
              </Routes>
            </Suspense>
          </main>
          <Scroll />
          <ContactUsFloatingButton />
          <Footer />
        </Box>
      </Box>
    </div>
  )
}

export default App
