import React from 'react'
import {
  Box,
  Typography,
  Link,
  IconButton,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import logoImg from '../../assests/img/logo.png'
import saudi from '../../assests/img/saudiflag.png'

import {
  Facebook,
  LinkedIn,
  WhatsApp,
  Instagram,
  YouTube,
  LocationOn,
  Email,
  Phone,
} from '@mui/icons-material'
const Footer = () => {
  const { t } = useTranslation()

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      component="footer"
      sx={{
        background: `linear-gradient(135deg,${theme.palette.secondary.footerOther} 0%, ${theme.palette.secondary.footerGreen} 100%)`,
        color: 'primary.contrastText',
        py: 4,
        textAlign: 'center',
        mt: 10,
        mb: 3,
        width: '100%',
        // backgroundImage: `url(${footerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {' '}
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {/* Logo and Details */}
          <Box sx={{ width: { xs: '100%', md: '25%' }, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img
                src={logoImg}
                alt="logo"
                style={{ maxWidth: '100%', height: 'auto' }}
                loading="lazy"
              />
            </Box>
            <Typography variant="body2" align="center">
              {t('footer.detial')}
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 4 }}
            >
              {[
                {
                  icon: <Facebook />,
                  url: 'https://www.facebook.com/people/Matrix-For-Business-Apps/61555985182813/?mibextid=LQQJ4d&rdid=jq7LiIEAWDKtKHBK&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F8iCRFSeGYJ7n8gEK%2F%3Fmibextid%3DLQQJ4d',
                  alt: 'Visit our Facebook page',
                },
                {
                  icon: <LinkedIn />,
                  url: 'https://www.linkedin.com/company/matrix-for-business-apps/',
                  alt: 'Visit our LinkedIn page',
                },
                {
                  icon: <WhatsApp />,
                  url: 'https://wa.me/966564217504',
                  alt: 'talk to us on WhatsApp',
                },
                {
                  icon: <Instagram />,
                  url: 'https://www.instagram.com/matrix.erp/',
                  alt: 'Visit our Instagram page',
                },
                {
                  icon: <YouTube />,
                  url: 'https://www.youtube.com/channel/UCGV7aVy9U6M-q6PIrfFvEvg',
                  alt: 'Visit our YouTube page',
                },
              ].map((social, index) => (
                <IconButton
                  key={index}
                  href={social.url}
                  color="inherit"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  alt={social.alt}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Box>

          {/* Explore Section */}
          <Box sx={{ width: { xs: '50%', sm: '25%', md: '15%' }, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              {t('footer.explore')}
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0, mt: 3 }}>
              {['about_us', 'contact_us', 'e_invoicing'].map(item => (
                <Box component="li" key={item} sx={{ mb: 2 }}>
                  <Link
                    href={`/${item.replace('_', '')}`}
                    color="inherit"
                    underline="hover"
                  >
                    {t(`footer.${item}`)}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Products Section */}
          <Box sx={{ width: { xs: '50%', sm: '25%', md: '14%' }, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              {t('footer.products')}
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0, mt: 3 }}>
              {['erp', 'crm', 'loyalty'].map(item => (
                <Box component="li" key={item} sx={{ mb: 2 }}>
                  <Link
                    href={`/products#${item}`}
                    color="inherit"
                    underline="hover"
                  >
                    {t(`footer.${item}`)}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Services Section */}
          <Box sx={{ width: { xs: '50%', sm: '25%', md: '14%' }, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              {t('footer.services')}
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0, mt: 3 }}>
              {[
                'erpc',
                //   'e_invoicing',
                'deve',
                'mana',
                'ecomm',
                'net',
                'cloud',
              ].map(item => (
                <Box component="li" key={item} sx={{ mb: 2 }}>
                  <Link
                    href={`/services#${item.replace('_', '-')}`}
                    color="inherit"
                    underline="hover"
                  >
                    {t(`footer.${item}`)}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Contact Us Section */}
          <Box
            sx={{
              width: { xs: '50%', sm: '25%', md: '22%' },
              mb: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t('footer.contact_us')}
            </Typography>
            <a href="https://maps.app.goo.gl/FDCny18D4evk3EpD6">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <LocationOn fontSize="small" sx={{ mr: 1, color: 'black' }} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: {
                      sm: '0.9rem',
                      md: '1rem',
                      lg: '1.2rem',

                      textDecoration: 'underline',
                      textDecorationColor: '#000000',
                    },
                    color: 'primary.contrastText',
                    direction: 'ltr',
                  }}
                >
                  {t('footer.locations.jeddah')}
                </Typography>
                <img
                  src={saudi}
                  alt="Saudi Arabia"
                  loading="lazy"
                  style={{
                    width: '20px',
                    height: '15px',
                    marginLeft: '8px',
                    verticalAlign: 'middle',
                  }}
                />
              </Box>
            </a>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
                mt: 2,
              }}
            >
              <Email fontSize="small" sx={{ mr: 1 }} />
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText' }}
              >
                {t('footer.info.email')}
              </Typography>
            </Box>
            <a href="tel:+966564217504">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <Phone fontSize="small" sx={{ mr: 1, color: 'black' }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: 'primary.contrastText',
                    textDecoration: 'underline',
                    textDecorationColor: '#000000',
                  }}
                >
                  {t('footer.info.phone')}
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>

        {/* Bottom Section */}
        <Box
          sx={{
            mt: 6,
            pt: 3,
            borderTop: 1,
            borderColor: 'rgba(255, 255, 255, 0.2)',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mb: isMobile ? 2 : 0 }}>
            <Typography variant="body2" align={isMobile ? 'center' : 'left'}>
              {t('footer.copy_rights')} {new Date().getFullYear()},{' '}
              {t('footer.all_rights')}
            </Typography>
            <Typography variant="body2" align={isMobile ? 'center' : 'left'}>
              {t('footer.copy_rights_end')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
