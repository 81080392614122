import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  useTheme,
} from '@mui/material'
import Simplerobust from '../../../assests/img/products/CRM/Simplerobust.webp'
import ProcessOriented from '../../../assests/img/products/CRM/ProcessOriented.webp'
import FastEfficient from '../../../assests/img/products/CRM/FastEfficient.webp'
import multitask from '../../../assests/img/products/CRM/multitask.webp'
import ShineBorder from '../../common/ShineBorder'
import { useTranslation } from 'react-i18next'

export default function CRMWhyCards() {
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const greenColors = theme.palette.custom?.greenColors || [
    '#4CAF50',
    '#45a049',
    '#357a38',
  ]
  const { t } = useTranslation()
  const papersContent = [
    {
      title: t('products.simple_title'),
      description: t('products.simple_para'),
      img: Simplerobust,
    },
    {
      title: t('products.fast_title'),
      description: t('products.fast_para'),
      img: FastEfficient,
    },
    {
      title: t('products.multitasker_title'),
      description: t('products.multitasker_para'),
      img: multitask,
    },
    {
      title: t('products.process_oriented_title'),
      description: t('products.process_oriented_para'),
      img: ProcessOriented,
    },
  ]
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
            lg: 'repeat(4, 1fr)',
          },
          gap: 3,
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {papersContent.map((content, index) => (
          <ShineBorder
            key={index}
            borderRadius={16}
            // borderWidth={4}
            duration={8}
            color={greenColors}
            sx={{
              display: 'block',
              height: '100%',
            }}
          >
            <Card
              sx={{
                height: '98%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: 'inherit',
                backgroundColor: whiteShades[index % whiteShades.length],
                overflow: 'hidden',
                m: 0.25,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '5%',
                  right: rightLocation[index % rightLocation.length],
                  width: '30vw',
                  height: '30vw',
                  paddingBottom: '30%',
                  backgroundColor:
                    whiteShades[(index + 1) % whiteShades.length],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '50%',
                  transform: 'rotate(45deg)',
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '5%',
                  // right: rightLocation[index % rightLocation.length],
                  width: '50vw',
                  height: '50vw',
                  paddingBottom: '30%',
                  backgroundColor:
                    whiteShades[(index + 1) % whiteShades.length],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '50%',
                  transform: 'rotate(45deg)',
                  zIndex: 0,
                }}
              />
              <CardContent
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 3,
                }}
              >
                <img
                  loading="lazy"
                  src={content.img}
                  alt={content.title}
                  style={{
                    display: 'block',
                    height: '60px',
                    width: '60px',
                    marginBottom: theme.spacing(2),
                  }}
                />
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: 'bold',
                    mb: 2,
                    textAlign: 'center',
                    color: 'secondary.main',
                  }}
                >
                  {content.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textAlign: 'center', color: 'secondary.main' }}
                >
                  {content.description}
                </Typography>
              </CardContent>
            </Card>
          </ShineBorder>
        ))}
      </Box>
    </Container>
  )
}
