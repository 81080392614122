import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MarqueeDemo } from './NEWERPFeature'
export default function ERPFeatures() {
  // const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          // padding: isMobile ? '1rem' : '3rem',
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography variant="h1" component="h1" gutterBottom>
            <Typography
              component="span"
              variant="h1"
              sx={{
                backgroundColor: 'secondary.main', // Highlight background
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {t('products.our')}
            </Typography>
          </Typography>
          {!isArabic && (
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem', // Add padding for emphasis
              }}
            >
              {t('products.features')}
            </Typography>
          )}
          {/* {t('home.business')} */}
          <Typography variant="body1" mt="3%">
            {/* {t('home.paragraph')} */}
            {t('products.features_para')}
          </Typography>
        </Box>
      </Box>
      {/* <BackgroundCircle Nb={1} position={{ top: '-40vh', left: '20vw' }} />
      <BackgroundCircle Nb={0} position={{ top: '-30vh', right: '30vw' }} />
      <SemicircularCardList />*/}
      <MarqueeDemo />
    </Box>
  )
}
