import React, { useEffect } from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7' // Material UI Icons for light and dark mode
import { useThemeContext } from '../../theme'

const DarkModeToggle = () => {
  const theme = useTheme() // Access the current theme
  const { mode, toggleTheme } = useThemeContext() // Use mode from the context

  useEffect(() => {
    // Optional: Sync the mode with local storage or system preferences
    // localStorage.setItem('themeMode', mode);
  }, [mode])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={toggleTheme}
        sx={{
          color:
            theme.palette.primary.main === '#FFFFFF' ? '#000000' : '#FFFFFF',
        }}
      >
        {mode === 'light' ? (
          <Brightness7Icon /> // Sun icon for light mode
        ) : (
          <Brightness4Icon /> // Moon icon for dark mode
        )}
      </IconButton>
    </Box>
  )
}

export default DarkModeToggle

// import React from 'react'
// import { Switch, FormControlLabel } from '@mui/material'
// import { useThemeContext } from '../../theme'

// const DarkModeToggle = () => {
//   const { toggleTheme } = useThemeContext()

//   return (
//     <FormControlLabel
//       control={<Switch onChange={toggleTheme} />}
//       label="Dark Mode"
//     />
//   )
// }

// export default DarkModeToggle
