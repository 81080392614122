// src/theme.js
import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useDirection from './hooks/useDirection'

import background from './assests/img/background/background.webp'
/*import two from './assests/img/background/2.webp'
import three from './assests/img/background/3.webp'*/
import two from './assests/img/background/1.webp'
import three from './assests/img/background/2.webp'

import darkbackground from './assests/img/background/dark3.webp'
import darktwo from './assests/img/background/1dark.webp'
import darkthree from './assests/img/background/2dark.webp'
/*import darktwo from './assests/img/background/dark2.webp'
import darkthree from './assests/img/background/dark3.webp'*/
import darkcontact from './assests/img/background/darkcontact.webp'
import contact from './assests/img/background/contact.webp'

import darkerp from './assests/img/background/erpdark.webp'
import erp from './assests/img/background/erp.webp'

import darkcrm from './assests/img/background/crmdark.webp'
import crm from './assests/img/background/crm.webp'

import darklyt from './assests/img/background/lytdark.webp'
import lyt from './assests/img/background/lyt.webp'
import darkmain from './assests/img/AllbgImg/darkmain.webp'
import main from './assests/img/AllbgImg/main.webp'
const ThemeContext = createContext()
const ThemeProviderComponent = ({ children }) => {
  const direction = useDirection()
  // State to toggle between light and dark mode
  const [mode, setMode] = useState('light')
  const toggleTheme = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
  }
  // Check user's system preference for dark mode on component mount
  useEffect(() => {
    const systemPrefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches
    setMode(systemPrefersDark ? 'dark' : 'light')
  }, [])
  const theme = useMemo(
    () =>
      createTheme({
        direction,
        backgroundImage: {
          main:
            mode === 'light'
              ? `
          url(${background}), 
          url(${two}), 
          url(${three})
        `
              : `
          url(${darkbackground}),
         url(${darktwo}), 
          url(${darkthree})
        `,
          contact: mode === 'light' ? contact : darkcontact,
          erp: mode === 'light' ? erp : darkerp,
          crm: mode === 'light' ? crm : darkcrm,
          lyt: mode === 'light' ? lyt : darklyt,
          mainhome: mode === 'light' ? main : darkmain,
        },
        palette: {
          mode: mode, // Set mode dynamically, will switch between 'light' or 'dark'
          primary: {
            main: mode === 'light' ? '#FFFFFF' : '#121212', // Light mode uses white, dark mode uses dark background
            contrastText: mode === 'light' ? '#000000' : '#FFFFFF', // Text contrast based on the mode
          },
          secondary: {
            main: '#64c290', // Secondary color stays the same for both light and dark modes
            footerGreen: 'rgba(100, 194, 144, 0.6)',
            footerOther:
              mode === 'light'
                ? 'rgba(255, 255, 255, 0.6)'
                : 'rgba(0, 0, 0, 0.6)',
            contrastText: '#FFFFFF',
          },
          Navbar: {
            link: mode === 'light' ? '#929292' : '#CCCCCC', // Lighter link in dark mode
            hover: mode === 'light' ? '#64b6a1' : '#4e9b83', // Adjust hover color for dark mode
            active: mode === 'light' ? '#4e9b83' : '#64b6a1', // Active state color
            LoginButton: {
              color: mode === 'light' ? '#202020' : '#FFFFFF', // Dark text for light mode and light text for dark mode
              borderColor: '#559F82',
              hover: { color: '#FFFFFF', background: '#64c290' },
            },
          },
          background: {
            default: mode === 'light' ? '#f5f5f5' : '#121212', // Light mode uses light background, dark mode uses dark background
            paper: mode === 'light' ? '#FFFFFF' : '#333333', // Paper color for dark mode
            marquee: mode === 'light' ? 'grey.100' : '#606060',
          },
          text: {
            primary: mode === 'light' ? '#000000' : '#FFFFFF', // Light mode text is black, dark mode text is white
            secondary: mode === 'light' ? '#333333' : '#AAAAAA', // Lighter secondary text color in dark mode
            third: mode === 'light' ? '#808080' : '#999999', // Lighter third text color in dark mode
          },
          error: { main: '#D32F2F' },
          warning: { main: '#FFA000' },
          info: { main: '#1976D2' },
          success: { main: '#388E3C' },
          NextIcon: { background: '#ffffff33', hover: '#ffffff66' },
          button: {
            primary: { main: '#64c290', hover: '#5bb78c' },
            secondary: { main: '#FFFFFF', hover: '#f0f0f0' },
          },
          card: {
            background: '#FFFFFF',
            shadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          },
          backgroundColor: mode === 'light' ? '#f4f4f4' : '#000000',
          custom: {
            yellowColor: '#efdf00',
            whiteShades:
              mode === 'light'
                ? [
                    '#f0f0f0',
                    '#f7f7f7',
                    '#fafafa',
                    '#ffffff',
                    '#e0e0e0',
                    '#ffffff',
                    '#ffffff',
                  ]
                : [
                    '#212121', // Dark Gray
                    '#333333', // Darker Gray
                    '#424242', // Even Darker Gray
                    '#616161', // Medium Dark Gray
                    '#757575', // Medium Gray
                    '#9e9e9e', // Light Gray
                    '#bdbdbd', // Very Light Gray
                  ],
            yellowColors: ['#cfc75b', '#a29b2e'],
            lightYellowColors: ['#e0d000', '#dfd000', '#f9e800', '#fae800'],
            greenColors: [
              '#67c18c',
              '#559f82',
              '#2a7457',
              '#83c49d',
              '#8ec9a6',
            ],
            lightGreenColors: [
              '#dbece2',
              '#b0ddc2',
              '#d4ecde',
              '#d4ecd8',
              '#c1e2cf',
              '#e4f2ea',
              '#c2e5d0',
            ],
            cardColors: ['#cfc75b', '#67c18c', '#559f82'],
          },
        },
        breakpoints: {
          values: { xs: 0, sm: 600, md: 900, lg: 1200, an: 1360, xl: 1536 },
        },
        typography: {
          h1: {
            fontSize: '2.5rem', // Default font size
            '@media (min-width:600px)': {
              fontSize: '2.5rem',
            },
            '@media (min-width:900px)': {
              fontSize: '3rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '4rem',
            },
            '@media (min-width:1536px)': {
              fontSize: '4.5rem',
            },
            fontWeight: 'bold',
            color: mode === 'light' ? '#000000' : '#FFFFFF',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          h2: {
            fontSize: '2rem',
            '@media (min-width:600px)': {
              fontSize: '2rem',
            },
            '@media (min-width:900px)': {
              fontSize: '2.5rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '3rem',
            },
            '@media (min-width:1536px)': {
              fontSize: '4rem',
            },
            fontWeight: 'bold',
            color: mode === 'light' ? '#333333' : '#FFFFFF',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          h3: {
            fontSize: '1.2rem',
            color: mode === 'light' ? '#333333' : '#FFFFFF',
            direction: direction,
            fontFamily: 'Montserrat-Arabic',
          },
          body1: {
            fontSize: '1rem',
            '@media (min-width:600px)': {
              fontSize: '1rem',
            },
            '@media (min-width:900px)': {
              fontSize: '1.1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.3rem',
            },
            color: mode === 'light' ? '#000000' : '#FFFFFF',
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          body2: {
            fontSize: '0.9rem',
            '@media (min-width:600px)': {
              fontSize: '0.9rem',
            },
            '@media (min-width:900px)': {
              fontSize: '1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.3rem',
            },
            color: '#808080',
            whiteSpace: 'pre-line',
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            lineHeight: 1.2,
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          link: {
            fontWeight: 600,
            fontSize: '0.9rem',
            '@media (min-width:600px)': {
              fontSize: '0.9rem',
            },
            '@media (min-width:900px)': {
              fontSize: '1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.3rem',
            },
          },
          h4: {
            color: mode === 'light' ? '#000000' : '#FFFFFF',
            direction: direction,
            fontSize: '1.5rem', // Default font size
            '@media (min-width:600px)': {
              fontSize: '1rem',
            },
            '@media (min-width:900px)': {
              fontSize: '2.5rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '2rem',
            },
            '@media (min-width:1536px)': {
              fontSize: '2rem',
            },
          },
          languageButton: { backgroundColor: '#68B266' },
          subtitle1: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            lineHeight: 1.2,
            direction: direction,
            fontFamily: 'Montserrat-Arabic',
          },
        },
        custom: {
          rightLocation: [
            '50%',
            '25%',
            '30%',
            '70%',
            '60%',
            '1%',
            '2%',
            '5%',
            '10%',
            '40%',
          ],
        },
      }),
    [direction, mode]
  )

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProviderComponent
export const useThemeContext = () => useContext(ThemeContext)
