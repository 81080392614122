import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ERPModulesCards from './ERPModulesCards'

export default function ERPModules() {
  const { t } = useTranslation()
  const text = {
    title: t('products.erp_matrix_title'),
    titleGreen: t('products.erp_title_green'),
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        justifyContent: 'space-between',
        mb: '5%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' }, //isMobile ? '1rem' : '3rem',

          maxWidth: '90vw',
          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography variant="h2" /*component="h1"*/ gutterBottom>
            <Typography
              component="span"
              variant="h2"
              sx={{
                //   backgroundColor: 'secondary.main',
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {text.title}
            </Typography>
          </Typography>
          <Typography
            component="span"
            variant="h2"
            sx={{
              backgroundColor: 'secondary.main',
              // Highlight background
              color: 'primary.contrastText', // Highlight text color
              padding: '0 0.5rem', // Add padding for emphasis
            }}
          >
            {text.titleGreen}
          </Typography>
        </Box>
      </Box>
      <ERPModulesCards />
    </Box>
  )
}
