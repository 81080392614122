import { Box, Typography } from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'
import BackgroundCircle from '../../common/BackgroundCircle'

export default function Main({
  computerImg,
  mobileImg,
  firstLine,
  SecondLine,
  highlightedSecondLone,
  firstPara,
  secondPara,
}) {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  return (
    <Box
      sx={{
        mt: { xs: '120px', sm: '100', md: '72px', lg: '72px', xl: '0' },

        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, // Stack on mobile
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: {
          xs: 'none',
          md: 'none',
          lg: `url(${computerImg})`,
        }, //!isLarge ? `url(${computerImg})` : 'none', // Background image for larger screens
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: { xs: 'none', md: 'none', lg: '100vh' }, // !isMobile ? '100vh' : 'none',
        zIndex: 1,
      }}
    >
      <BackgroundCircle Nb={0} position={{ top: '-60vh', left: '5vw' }} />

      {/* Left Column: Text */}
      <Box
        sx={{
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          pr: { md: '1rem', lg: '0' }, // isMobile ? '1rem' : '3rem',
          maxWidth: { xs: '100%', md: '100%', lg: '50%', xl: '50%' },
          // isMobile ? '100%' : isExtraLarge ? '100%' : '40%',
          justifyContent: 'center',
          alignItems: 'center',
          //justifyContent: 'space-between',
          backgroundSize: '50%',
          ml: { xs: '0', md: '0', lg: '5' },

          width: { xs: '75%', md: '50%', lg: `50%` },
          margin: { xs: '0 1rem', md: '0 2rem' },
        }}
      >
        <Typography
          align="center"
          variant="h2"
          display="block"
          sx={{ whiteSpace: 'initial', mb: 1 }}
        >
          {firstLine}
        </Typography>
        <Typography
          align="center"
          variant="h2"
          display="block"
          sx={{ whiteSpace: 'initial', mb: 6 }}
        >
          {SecondLine}
          <Typography
            align="center"
            // variant="h2"
            color="secondary"
            sx={{
              backgroundColor: 'secondary.main', // Highlight background
              color: 'primary.contrastText', // Highlight text color
              display: 'inline', // Keep ERP on the same line
              mx: 1, // Add some margin for spacing
              mb: '0', // Add padding for emphasis
            }}
            gutterBottom
          >
            {highlightedSecondLone}
          </Typography>
        </Typography>
        <Typography variant="body2" sx={{ mb: 6 }}>
          {firstPara}
        </Typography>
        <Typography variant="body2">{secondPara}</Typography>
      </Box>

      {/* Right Column: Image */}
      {!isLarge ? (
        <Box sx={{ flex: 1 }}>
          {/* Image for larger screens is part of the background */}
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            mt: 4,
            mb: 4,
            minHeight: '200px', // Ensures space is reserved for the image
            position: 'relative', // Enables positioning adjustments
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            loading="lazy"
            src={mobileImg} // Image for mobile
            alt="Mobile App Showcase"
            style={{
              width: '100%',
              maxWidth: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 4,
              //height: '100%',
              objectFit: 'cover',
            }} // Responsive styling
          />
        </Box>
      )}
    </Box>
  )
}
